<template>
	<div id="app">
		<router-view />
		<ul class="component tabbar" v-if="hasTabBar.includes(currentPath)&&isCheck">
			<li 
				v-for="(item, index) in tabBar"
				:key="index"
				:class="{ active: index == active }"
				@click="changeTab(item, index)"
			>
				<van-image v-show="index == active" class="icon-image" :src="item.activeIcon"></van-image>
				<van-image v-show="index != active" class="icon-image" :src="item.icon"></van-image>
				<div class="bar-text">{{ item.text }}</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
	name: 'App',
	watch: {
		$route: function (val) {
			this.currentPath = this.$route.path || '/';
			this.active = this.$route.query.active || 0;
			if (val.path == '/login') {
				window.document.title = '中考版登录';
			} else {
				window.document.title = '高分考鉴';
			}
		}
	},
	data() {
		return {
			currentPath: '/',
			hasTabBar: ['/'],
			active: 0,
			tabBar: [
				{
					icon: require('@/assets/icon/home0.png'),
					activeIcon: require('@/assets/icon/home1.png'),
					text: '首页',
					url: '/'
				},
				{
					icon: require('@/assets/icon/user0.png'),
					activeIcon: require('@/assets/icon/user1.png'),
					text: '我的',
					url: '/editUser'
				}
			],
			isCheck:  this.$store.getters['userCenter/getUserInfs'].select_subject
		}	
	},
	mounted() {
		console.log(this.$store.getters['userCenter/getUserInfs'].select_subject)
	},
	destroyed() {},
	methods: {
		changeTab(item, idx) {
			this.active = idx;
			this.$router.push({
				path: item.url,
				query: {
					active: idx
				}
			});
		}
	}
};
</script>

<style lang="scss">
@import 'assets/css/baseStyle.scss';
@import 'assets/css/iconfont.css';
@import 'assets/css/questionStyle.scss';
</style>
<style lang="scss" scoped>
#app {
	width: 100%;
	height: 100%;
}
.tabbar {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 200;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 0.98rem;
	background: #fff;
	border-top: 1px #dcdcdc solid;
	li {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		i {
			line-height: 1;
			font-size: 0.4rem;
		}
		.bar-text {
			font-size: 0.24rem;
			font-weight: 400;
			color: #333;
			line-height: 0.34rem;
		}
		.icon-image {
			width: 0.3rem;
			height: 0.3rem;
		}
	}
	.active {
		i,
		.bar-text {
			color: #28b396;
		}
	}
}

</style>
